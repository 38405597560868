<template>
	<div class="financeManage">
		<el-card>
			<!-- 按钮区域 -->
			<div class="btnsArea">
				<el-button type="primary" @click="addOrder">添加</el-button>
			</div>
			<!-- 订单区域 -->
			<div class="orderArea">
				<!-- 表格区域 -->
				<el-table ref="orderTable" :data="orderList" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowOrderClass">
					<el-table-column prop="packetId" label="结算编号" align="center"></el-table-column>
					<el-table-column prop="companyName" label="机构名称" align="center"></el-table-column>
					<el-table-column prop="settlementTime" label="结算时段" align="center"></el-table-column>
					<el-table-column prop="settlementMoney" label="结算金额" align="center"></el-table-column>
					<el-table-column prop="applyTime" label="申请时间" align="center"></el-table-column>
					<el-table-column prop="applyUser" label="申请人" align="center"></el-table-column>
					<el-table-column prop="status" label="状态" align="center">
						<!-- <template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.status == 1)">经办</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status == 2">已完成</el-tag>
							<el-tag effect="dark" type="danger" v-else-if="scope.row.status == 3">已撤销</el-tag>
							<el-tag effect="dark" type="warning" v-else-if="scope.row.status == 4">审核失败</el-tag>
						</template> -->
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.status === 1">{{ "经办" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === 2">{{ "已完成" }}
							</el-tag>
							<el-tag effect="dark" type="danger" v-else-if="scope.row.status === 3">{{ "已撤销" }}
							</el-tag>
							<el-tag effect="dark" type="warning" v-else-if="scope.row.status === 4">{{ "审核失败" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" min-width="150px">
						<template slot-scope="scope">
							<!-- 	<el-button v-if="scope.row.status == 1 || scope.row.status == 4" size="mini" type="primary"
								@click="revocationClick(scope.row)">撤回</el-button> -->
							<el-button size="mini" type="primary" @click="checkClick(scope.row)">查看</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<!-- 添加 -->
			<el-dialog :visible.sync="addVisible" width="70%" @close="handleClose">
				<el-row :gutter="20" style="margin-bottom: 20px;">
					<el-col>
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px;">订单开始时间: </div>
							<el-date-picker v-model="addform.starTime" type="date" placeholder="订单开始时间"
								value-format="yyyy-MM-dd">
							</el-date-picker>
							<div style="margin: 0 20px;">到: </div>
							<el-date-picker v-model="addform.endTime" type="date" placeholder="订单结束时间"
								value-format="yyyy-MM-dd">
							</el-date-picker>
							<el-button size="mini" type="primary" style="margin-left: 20px;" @click="getInfoInit">查询
							</el-button>
						</div>
					</el-col>
				</el-row>

				<el-table :data="addTableData" style="width: 100%" tooltip-effect="dark" highlight-current-row stripe
					border :header-cell-style="rowOrderClass" max-height="500" @select="selectionChange"
					@select-all="selectionChange">
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="payNo" label="第三方订单流水号" align="center"></el-table-column>
					<el-table-column prop="payTime" label="订单时间" align="center"></el-table-column>
					<el-table-column prop="payChannel" label="订单来源" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.payChannel === '1'">{{ "微信" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '2'">{{ "支付宝" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '3'">{{ "帐户余额" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '4'">{{ "二维码" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="memberName" label="下单用户" align="center"></el-table-column>
					<el-table-column prop="payType" label="支付类型" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.payType === '0'">{{ "预付" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payType === '1'">{{ "完成支付" }}</el-tag>
                            <el-tag effect="dark" v-else-if="scope.row.payType === '2'">{{ "补充收款" }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="isRefund" label="是否退款" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.isRefund === 0">{{ "未退款" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 1">{{ "已退款" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 2">{{ "已申请" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 3">{{ "退款失败" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="payFee" label="订单金额" align="center"></el-table-column>

                    <el-table-column prop="carNum" label="车牌号" align="center"></el-table-column>
                    <el-table-column prop="startAddress" label="起始地" align="center"></el-table-column>
                    <el-table-column prop="endAddress" label="目的地" align="center"></el-table-column>
                    <el-table-column prop="memberName" label="会员姓名" align="center"></el-table-column>
                    <el-table-column prop="memberMobile" label="会员手机号" align="center"></el-table-column>
				</el-table>
				<el-row :gutter="20" style="margin-top: 20px;">
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">总金额: </div>
							<el-input v-model="addform.settlementMoney" disabled>
							</el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">确定金额: </div>
							<el-input v-model="addform.settlementFixMoney">
							</el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">结算月份: </div>
							<el-date-picker v-model="addform.applyTime" type="month" placeholder="结束时间"
								value-format="yyyy-MM" />
						</div>
					</el-col>
				</el-row>

				<span slot="footer" class="dialog-footer">
					<el-button @click="addVisible = false">取 消</el-button>
					<el-button type="primary" @click="applyFor">申 请</el-button>
				</span>
			</el-dialog>

			<!-- 查看详情 -->
			<el-dialog :visible.sync="viewVisible" width="70%">
				<el-table :data="viewTableData" style="width: 100%" tooltip-effect="dark" highlight-current-row stripe
					border :header-cell-style="rowOrderClass" max-height="500">
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="payNo" label="第三方订单流水号" align="center"></el-table-column>
					<el-table-column prop="payTime" label="订单时间" align="center"></el-table-column>
					<el-table-column prop="payChannel" label="订单来源" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.payChannel === '1'">{{ "微信" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '2'">{{ "支付宝" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '3'">{{ "帐户余额" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '4'">{{ "二维码" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="memberName" label="下单用户" align="center"></el-table-column>
					<el-table-column prop="payType" label="支付类型" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.payType === '0'">{{ "预付" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payType === '1'">{{ "完成支付" }}</el-tag>
                            <el-tag effect="dark" v-else-if="scope.row.payType === '2'">{{ "补充收款" }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="isRefund" label="是否退款" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.isRefund === 0">{{ "未退款" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 1">{{ "已退款" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 2">{{ "已申请" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 3">{{ "退款失败" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="payFee" label="订单金额" align="center"></el-table-column>
				</el-table>

				<el-row :gutter="20" style="margin-top: 20px;">
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">总金额: </div>
							<el-input v-model="viewform.settlementMoney" disabled>
							</el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">确定金额: </div>
							<el-input v-model="viewform.settlementFixMoney" disabled>
							</el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">申请月份: </div>
							<el-date-picker v-model="viewform.applyTime" type="month" placeholder="结束时间"
								value-format="yyyy-MM" disabled />
						</div>
					</el-col>
				</el-row>

				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="viewVisible = false">确 定</el-button>
				</span>
			</el-dialog>

		</el-card>
	</div>
</template>

<script>
export default {
  name: 'financeManage',
  components: {},
  data () {
    return {
      orderList: [], // 订单表格
      addVisible: false, // 添加,
      addTableData: [], // 添加订单表格
      addform: {
        starTime: '', // 订单开始时间
        endTime: '', // 订单结束时间
        settlementMoney: '', // 总金额
        settlementFixMoney: '', // 确定金额
        applyTime: '' // 申请月份
      },
      infoPacketItmeList: [], // 当前选中的订单
      viewVisible: false, // 查看详情dialog
      viewTableData: [], // 详情Table
      viewform: {
        settlementMoney: '', // 总金额
        settlementFixMoney: '', // 确定金额
        applyTime: '' // 申请月份
      }
    }
  },
  created () {
    this.getOrderList()
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    // 给订单表格头部设定背景色
    rowOrderClass ({
      row,
      rowIndex
    }) {
      return 'background:#2196fb;color:#fff'
    },
    // 给退款表格头部设定背景色
    rowRefundClass ({
      row,
      rowIndex
    }) {
      return 'background:#ccc'
    },
    // 获取订单列表
    async getOrderList () {
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/getTInfoOrderFeeList', {
        companyId: window.sessionStorage.getItem('companyId'),
        pageNo: 1,
        pageSize: 20,
        statuss: ['1', '3', '4']
      })
      // if (res.data.length === 0) return this.$message.error('尚无查询结果')
      this.orderList = res.data.records
      console.log(res.data, '订单列表')
    },
    // 时间转换格式    格式YYYY-MM-DD
    getNowFormatDate (v) {
      var date = v
      var seperator1 = '-'
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    },
    handleClose (done) { // dialog关闭前回调
      this.addform.applyTime = ''
      this.addform.settlementMoney = ''
      this.addform.settlementFixMoney = ''
      this.addTableData = []
    },
    addOrder () {
      this.addVisible = true
      // this.getInfoInit()
      // this.getPacketItme()
    },
    // 订单添加列表
    async getInfoInit () {
      const id = window.sessionStorage.getItem('companyId')
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/getTInfoPacketItmeInit', {
        companyId: id,
        startTime: this.addform.starTime,
        endTime: this.addform.endTime

      })
      console.log(res, '订单添加列表查询')
      this.addTableData = res.data
    },
    // 获取申请包内信息
    async getPacketItme () {
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/getTInfoPacketItme', {
        packetId: 1
      })
      console.log(res, '获取申请包内信息')
    },
    // 勾选订单事件
    selectionChange (selection, row) {
      console.log(selection, row)
      let allMoney = 0
      selection.forEach(item => {
        if (item.isRefund !== 1) {
          allMoney += Number(item.payFee)
        }
      })
      this.addform.settlementMoney = Number(allMoney.toFixed(2))
      this.infoPacketItmeList = selection
    },
    async applyFor () { // 申请
      if (!this.addform.settlementMoney || this.addform.settlementMoney < 1) {
        return this.$message.error('请选择数据')
      } else if (!this.addform.settlementFixMoney || this.addform.settlementFixMoney < 1) {
        return this.$message.error('请输入确定金额')
      } else if (!this.addform.applyTime) {
        return this.$message.error('请选择申请月份')
      }
      const obj = {
        companyId: window.sessionStorage.getItem('companyId'),
        settlementTime: this.addform.applyTime,
        settlementMoney: this.addform.settlementMoney,
        settlementFixMoney: this.addform.settlementFixMoney,
        applyUser: window.sessionStorage.getItem('userName'),
        status: 1,
        infoPacketItmeList: this.infoPacketItmeList
      }
      console.log(obj)
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/insertInfoPacketItme', obj)
      console.log(res, '申请res')
      if (res.statusCode === '200') {
        this.addVisible = false
        this.getOrderList()
        return this.$message.success(res.resultDesc)
      } else {
        return this.$message.error(res.resultDesc)
      }
    },
    async revocationClick (row) {
      console.log(row)
      let dataObj = {}
      if (row.status == 1) {
        dataObj = {
          packetId: row.packetId,
          status: 3
        }
      } else if (row.status == 4) {
        dataObj = {
          packetId: row.packetId,
          status: 3,
          approvalUser: row.applyUser,
          approvalTime: row.applyTime
        }
      }
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/updateInfoPacket', dataObj)
      if (res.statusCode === '200') {
        this.getOrderList()
        return this.$message.success(res.resultDesc)
      } else {
        return this.$message.error(res.resultDesc)
      }
    },
    async checkClick (row) { // 查看详情
      console.log(row)
      this.viewform.settlementMoney = row.settlementMoney
      this.viewform.settlementFixMoney = row.settlementFixMoney
      this.viewform.applyTime = row.settlementTime
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/getTInfoPacketItme', {
        packetId: row.packetId
      })
      if (res.statusCode !== '200') {
        return this.$message.error(res.resultDesc)
      }
      this.viewTableData = res.data
      this.viewVisible = true
      console.log(res, '查看详情')
    }
  }
}
</script>
<style lang="less" scoped>
	.financeManage {
		width: 100%;
		height: 100%;

		/deep/ .btnsArea {
			display: flex;
			align-items: center;
			// justify-content: center;
			margin-bottom: 15px;

			.el-date-editor {
				margin-left: 20px;

				.el-range-separator {
					padding: 0;
				}
			}

			// .el-select {
			//   margin-right: 20px;
			// }
			.el-button {
				margin-left: 20px;
			}
		}

		.orderArea {
			margin-bottom: 20px;
		}

		.order-dialog,
		.refund-dialog,
		.liq-dialog {
			.dialog-Title {
				color: #0097fe;
				font-size: 20px;
				font-weight: 700;
			}

			/deep/ .el-form {
				.subtitle {
					color: #0097fe;
					font-size: 14px;
					font-weight: 700;
					margin-bottom: 20px;
					padding-left: 6px;
					border-left: 5px solid #0097fe;
				}

				.el-row {
					display: flex;

					.el-form-item {
						flex: 1;
						display: flex;

						.el-form-item__content {
							flex: 1;

							.el-date-editor {
								width: 100%;
							}

							.el-input {
								width: 100%;

								.el-input__inner {
									width: 100%;
								}
							}

							.el-select {
								width: 100%;
							}
						}
					}
				}
			}
		}

		/deep/ .order-dialog {
			width: 1800px;
			margin: 0;
			margin-left: 60px;

			.el-dialog {
				width: 100%;
				margin: 0;
			}
		}
	}
</style>
